import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next"

export default function NotFound() {
  const { t } = useTranslation()

  return (
    <Layout>
      <div id="roots">
        <SEO title={t("header.title")} />

        <h1>
          <Trans>header.title</Trans>
        </h1>
        <h2>
          <Trans>header.subtitle</Trans>
        </h2>
        <p>
          <Trans>error.notfound</Trans>
        </p>
      </div>
    </Layout>
  )
}
